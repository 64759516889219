import { defineStore } from "pinia";
import { ref } from "vue";
import type { BookPreventiveCare } from "~/types/booking-api/BookPreventiveCare";
import { PreventiveCareType } from "~/types/booking-api/PreventiveCareType";

export const useBookingStore = defineStore("booking", () => {
  const selectedHealthProfessionalDate = ref<string | null>(null);
  const selectedLabDate = ref<string | null>(null);
  const preventiveCareType = ref<PreventiveCareType>(PreventiveCareType.HAELSI_PREVENTIVE_CARE);
  const location = ref<null | number>(null);
  const bookedPreventiveCare = ref<BookPreventiveCare | null>(null);

  function setHealthProfessionalDate(date: string | null): void {
    selectedHealthProfessionalDate.value = date;
  }

  function setLabDate(date: string | null): void {
    selectedLabDate.value = date;
  }

  function setLocation(newLocation: null | number): void {
    location.value = newLocation;
  }

  function setPreventiveCareType(newPreventiveCareType: PreventiveCareType): void {
    preventiveCareType.value = newPreventiveCareType;
  }

  function setBookedPreventiveCare(newBookedPreventiveCare: BookPreventiveCare): void {
    bookedPreventiveCare.value = newBookedPreventiveCare;
  }

  const hasPreventiveCareDatesSelected = computed(
    () => selectedLabDate.value !== null && selectedHealthProfessionalDate.value !== null,
  );
  const hasAppointmentDateSelected = computed(() => selectedHealthProfessionalDate.value !== null);

  function $reset(): void {
    location.value = null;
    preventiveCareType.value = PreventiveCareType.HAELSI_PREVENTIVE_CARE;
    selectedHealthProfessionalDate.value = null;
    selectedLabDate.value = null;
    bookedPreventiveCare.value = null;
  }

  return {
    $reset,
    bookedPreventiveCare,
    hasAppointmentDateSelected,
    hasPreventiveCareDatesSelected,
    location,
    preventiveCareType,
    selectedHealthProfessionalDate,
    selectedLabDate,
    setBookedPreventiveCare,
    setHealthProfessionalDate,
    setLabDate,
    setLocation,
    setPreventiveCareType,
  };
});
